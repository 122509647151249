<template>
  <div id="shopifyIntegrationPage">
    <!-- Header -->
    <div class="mainHeader">Shopify Integration</div>

    <br />

    <!-- Loader - WEbPush settings -->
    <div class="loadingDiv" v-if="fetchingData" v-loading="true" style="min-height: 300px"></div>

    <div v-else-if="!existingInfo">
      <el-form :model="shopifyForm" ref="integrateForm" :rules="formRules">
        <!-- Vapid Settings Card -->
        <el-card class="box-card" shadow="never">
          <div slot="header">
            <span>Integration Settings</span>
          </div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="Shop URL" prop="shopUrl">
                <div>
                  <el-input v-model="shopifyForm.shopUrl"></el-input>
                  <div class="hint">Your Shop URL is formatted like mystore.myshopify.com.</div>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>

        <!--Submit Btn -->
        <div class="submitBtn">
          <el-button @click="onIntegrateShopify()" type="success">Integrate Shopify</el-button>
        </div>
      </el-form>
    </div>

    <div v-else>
      <!-- Shopify Integration Successs Card -->
      <el-card class="box-card" shadow="never">
        <div slot="header">
          <span>Integration Settings</span>
        </div>
        <div class="integrationSuccess">
          <div class="successIcon">
            <i class="el-icon-circle-check"></i>
          </div>
          <div class="successMessage">Shopify is integrated.</div>
          <div class="shopInfo">{{ existingInfo.shop }}</div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<style lang="scss" src="./shopifyIntegration.scss"></style>

<script>
import shopifyIntegrationComponent from './shopifyIntegrationComponent';
export default shopifyIntegrationComponent;
</script>
