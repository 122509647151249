import { getShopifyInfo } from './../../../services/shopify';
const { dataCollectionUrl } = require('./../../../services/settings');

export default {
  name: 'shopifyIntegration',
  data() {
    return {
      fetchingData: false,
      existingInfo: null,
      shopifyForm: {
        shopUrl: ''
      },

      // Form Validation
      formRules: {
        shopUrl: [
          {
            required: true,
            message: 'Please enter Shop URL.'
          }
        ]
      }
    };
  },

  methods: {
    onIntegrateShopify() {
      this.$refs['integrateForm'].validate((valid) => {
        if (!valid) {
          return;
        }

        // Redirect to integration page.
        let installUrl = dataCollectionUrl + `shopify/install?shop=${this.shopifyForm.shopUrl}`;
        console.log('Install url ', installUrl);
        location.href = installUrl;
      });
    }
  },
  mounted() {
    // Read existing shopify info
    getShopifyInfo(this)
      .then((result) => {
        this.existingInfo = result.data;
      })
      .catch(() => {});
  }
};
